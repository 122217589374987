import { Branches, TripStatus } from '../interfaces';

export enum Brand {
	SURA = 'sura',
	KAVAK = 'kavak',
	AUTECO = 'auteco',
	MAZDA = 'mazda',
	ACURA = 'acura',
	HONDA = 'honda',
	QUALITAS = 'qualitas',
	ASSA = 'assa',
	LAFISE = 'lafise',
	MMM = 'mmm',
	MMM_EMPLEADOS = 'mmm-empleados',
	SURA_CO = 'sura-co',
	INSPECCIONES_CSM = 'inspecciones-csm',
	MULTIPLE_ASISTENCIA_EN_VIAJE = 'multiple-asistencia-en-viaje',
	MULTIPLE_ASISTENCIA_EN_EL_HOGAR = 'multiple-asistencia-en-el-hogar',
	HUMAN_ADVANTAGE = 'human-advantage',
	SSS_AUTO = 'sss-auto',
	SSS_HOME = 'sss-home',
	BANESCO = 'banesco',
	ACERTA = 'acerta',
	LINCOLN = 'lincoln',
	SEGUROS_MUNDIAL = 'seguros-mundial',
	RICARDO_PEREZ = 'ricardo-perez',
	HOME_ON_DEMAND_PR = 'connect-home-repair-ondemand-pr',
	OTIUM = 'otium',
	KIA = 'kia',
	LEXUS = 'lexus',
	INFINITI = 'infiniti',
	MITSUBISHI = 'mitsubishi',
	HYUNDAI = 'hyundai',
	TOYOTA = 'toyota',
	INTERNACIONAL_DE_SEGUROS = 'internacional-de-seguros',
	HDI = 'hdi',
	FEDPA = 'fedpa',
	KINTO_ONE = 'kinto-one',
	ASC = 'asc',
	AUTOCHILANGO = 'autochilango',
	EPARK = 'epark',
}

export const BrandToCampaignMap: { [key: string]: string } = {
	[Brand.OTIUM]: 'OTIUM',
};

export const BRANCH = process.env.REACT_APP_HELIOS_BRANCH || '';
export const COMMON_API = process.env.REACT_APP_COMMON_API || '';
export const ON_DEMAND_ACCOUNT_ID = process.env.REACT_APP_ONDEMAND_ID || '';
export const ON_DEMAND_ACCOUNT_PTY_ID = process.env.REACT_APP_ONDEMAND_PTY_ID || '';
export const HOME_ON_ONDEMAND_ACCOUNT_ID = process.env.REACT_APP_HOME_ONDEMAND_ACCOUNT || '';
export const MEMBER_ACCOUNT_ID = process.env.REACT_APP_MEMBER_ID || '';
export const MEMBER_ACCOUNT_PTY_ID = process.env.REACT_APP_MEMBER_PTY_ID || '';
export const HELIOS_API = process.env.REACT_APP_HELIOS_API || '';
export const TWILIO_API = process.env.REACT_APP_TWILIO_API || '';
export const HELIOS_REGULAR_KEY = process.env.REACT_APP_HELIOS_REGULAR_KEY || '';
export const HELIOS_AUTO_DISPATCH_KEY = process.env.REACT_APP_HELIOS_AUTO_DISPATCH_KEY || '';
export const TAX_RATE = process.env.REACT_APP_TAX_RATE || '';
export const STRIPE_PR_KEY = process.env.REACT_APP_STRIPE_PR_KEY || '';
export const ATH_MOVIL_ENV = process.env.REACT_APP_ATH_MOVIL_ENV || '';
export const ATH_MOVIL_TOKEN = process.env.REACT_APP_ATH_MOVIL_TOKEN || '';
export const CALL_CENTER_PR = process.env.REACT_APP_CALL_CENTER || '';
export const CALL_CENTER_CR = process.env.REACT_APP_CALL_CENTER_CR || '';
export const CONNECT_SHARE_URL = process.env.REACT_APP_CONNECT_URL_SECOND_STOP_URL || '';
export const WHATSAPP_PR_WHITELABEL = process.env.REACT_APP_WHATSAPP_PR_WHITELABEL || '';
export const GTM_ID = process.env.REACT_APP_GTM_ID || '';
export const CS_API_KEY = process.env.REACT_APP_CS_API_KEY || '';
export const CS_API_URL = process.env.REACT_APP_CS_API_URL || '';
export const HEINDALL_API_URL = process.env.REACT_APP_HEINDALL_API_URL || '';
export const AUDIO_BUCKET = process.env.REACT_APP_AUDIO_BUCKET || '';
export const AUDIO_REGION = process.env.REACT_APP_AUDIO_REGION || '';
export const S3_BUCKET_NAME_PR = process.env.REACT_APP_S3_BucketPR || '';
export const S3_BUCKET_NAME_CR = process.env.REACT_APP_S3_BucketCR || '';
export const S3_BUCKET_NAME_PAN = process.env.REACT_APP_S3_BucketPAN || '';
export const S3_BUCKET_REGION_1 = process.env.REACT_APP_S3_BucketRegion1 || '';
export const S3_BUCKET_REGION_2 = process.env.REACT_APP_S3_BucketRegion2 || '';
export const PENDO_APIKEY = process.env.REACT_APP_PENDO_APIKEY || '';

/** MMM env variables */
export const MMM_AUTH_ENDPOINT = process.env.REACT_APP_MMM_AUTH_ENDPOINT || '';
export const MMM_CLIENT_ID = process.env.REACT_APP_MMM_CLIENT_ID || '';
export const MMM_REDIRECT_URI = process.env.REACT_APP_MMM_REDIRECT_URI || '';
export const MMM_SCOPE = process.env.REACT_APP_MMM_SCOPE || '';
export const MMM_ACCOUNT_ID = process.env.REACT_APP_MMM_ACCOUNT || '';
export const MMM_ACCOUNT_SFID_ACCOUNT = process.env.REACT_APP_MMM_SFIDACCOUNT || '';

export const WHITELABEL_BRANDS = [
	'lafise',
	'multiple-asistencia-en-viaje',
	'multiple-asistencia-en-el-hogar',
	'inspecciones-csm',
	'sura',
	'sura-co',
	'kavak',
	'auteco',
	'qualitas',
	'assa',
	'human-advantage',
	'honda',
	'acura',
	'mazda',
	'multi-auto',
	'sss-auto',
	'sss-home',
	'mmm',
	'mmm-empleados',
	'banesco',
	'lincoln',
	'acerta',
	'seguros-mundial',
	'ricardo-perez',
	'optima-seguros',
	'kia',
	'lexus',
	'infiniti',
	'mitsubishi',
	'hyundai',
	'toyota',
	'nissan',
	'general-de-seguros',
	'internacional-de-seguros',
	'hdi',
	'fedpa',
	'kinto-one',
	'sos',
	'asc',
	'autochilango',
];

export const GREYLABEL_BRANDS = ['atlas', 'kavak-mx', 'clupp', 'crabi', 'odeta', 'afirme', 'quan'];

export const SURVEY_V2_QUESTIONS_BRANDS = ['sos', 'sos-transportes-vip', 'bac'];

export const HIDE_SPLASH_LOGO_WHEN_WEBVIEW_BRANDS = ['multiple-asistencia-en-viaje'];
export const SHOW_VEHICLE_INFO_IN_SUMMARY_BRANDS = ['multiple-asistencia-en-viaje'];
export const HIDE_RECEIPT_AMOUNT_WHEN_TOTAL_IS_ZERO_BRANDS = ['multiple-asistencia-en-viaje'];
export const BRANDS_WITHOUT_EMAIL_VALIDATION = [
	Brand.MMM,
	Brand.INTERNACIONAL_DE_SEGUROS,
	Brand.MULTIPLE_ASISTENCIA_EN_EL_HOGAR,
];
/**
 * Array of brands exempt from customer contract requirements in the service confirmation process.
 */
export const SKIP_CONTRACT_BRANDS = ['connect', Brand.OTIUM, Brand.HOME_ON_DEMAND_PR, Brand.INTERNACIONAL_DE_SEGUROS];
export const HIDE_PLATE_FIELD = ['multi-auto', Brand.OTIUM];
export const SHOW_CONTINUE_WITH_ONDEMAND_BTN = [Brand.OTIUM];
/**
 * Brands that should display the On-Demand flow in English.
 * This may occur for several reasons. For example, if the user of an account does not have coverage, they are prompted to choose whether to continue with On-Demand and pay for the service.
 */
export const SHOW_ENGLISH_ONDEMAND: string[] = [Brand.OTIUM];
export const ADD_CAMPAIGN_WHEN_CONTINUING_WITH_ON_DEMAND = [Brand.OTIUM];
export const HIDE_FULL_NAME_FOR_CONTRACT_SEARCH = [Brand.OTIUM]; // Cuentas que ocultan el input de texto full name, texto que se usa para buscar el contrato.
export const ACCOUNTS_WITH_ENABLED_PLATE_FIELD = ['mmm'];
export const CORPORATE_ACCOUNTS = ['honda', 'acura', 'mazda'];
export const HONDA_DOMAIN = process.env.REACT_APP_HONDA_DOMAIN || '';
export const HONDA = 'honda';
export const MAZDA = 'mazda';
export const ACURA = 'acura';
export const MMM = 'mmm';
export const MMM_EMPLEADOS = 'mmm-empleados';
export const VANTI = 'vanti';
export const KIA = 'kia';
export const OPTIMA_SEGUROS = 'optima-seguros';
export const HDI = 'hdi';

export const MMM_ACCOUNT = '0014z00001ftMZxAAM';
export const QUALITAS_ACCOUNT = '0011Y00002c1UyJQAU';
export const HDI_MASCOTAS_Y_GENERALES_SFID = 'hdi-mascotas-y-generales';
export const CRABI_SFID = 'crabi';
export const GRUPO_MUTUAL_SFID = 'grupo-mutual';
export const ASC = 'asc';
export const AUTOCHILANGO = 'autochilango';
export const EPARK = 'epark';
export const VANTI_SFID = '0014z00001hHaFWAA0';

export const TRIP_STATUS_ORDER = {
	[TripStatus.NEW]: 1,
	[TripStatus.ACCEPTED]: 2,
	[TripStatus.ON_ROUTE]: 3,
	[TripStatus.ARRIVED]: 4,
	[TripStatus.TOWED]: 5,
	[TripStatus.FINISHED]: 6,
	[TripStatus.CANCELLED]: 7,
	[TripStatus.CANCELLED_BY_DRIVER]: 8,
	[TripStatus.PENDING_AUDIT]: 9,
};

export const ACTIVE_TRIP_STATUS = [TripStatus.ACCEPTED, TripStatus.ON_ROUTE, TripStatus.ARRIVED, TripStatus.TOWED];
export const LOADING_TRIP_STATUS = [TripStatus.NEW, TripStatus.CANCELLED_BY_DRIVER];
export const FINISHED_TRIP_STATUS = [TripStatus.PENDING_AUDIT, TripStatus.FINISHED];

export const CONNECT_BRAND_NAMES = ['connect-assistance', 'connect', 'connect-pr-ondemand'];

export const ONLY_TRACKING_ACCOUNTS = [
	'acerta',
	'sss-home',
	'clupp',
	'atlas',
	'kavak-mx',
	'clupp',
	'crabi',
	'odeta',
	'afirme',
];
export const ACCOUNTS_WITH_DISABLED_IN_HOUSE_SURVEY = [HDI_MASCOTAS_Y_GENERALES_SFID, CRABI_SFID];
export const ACCOUNTS_WITH_DISABLED_IN_HOUSE_SURVEY_TO_SOCIAL = [VANTI_SFID];
export const ACCOUNTS_TO_KEEP_SERVICE_TYPES_ON_CHECK_LIMIT = ['MMM'];

export enum MediaId {
	COINSPECT_CRC = 'COINSPECT_CRC',
	COINSPECT_PAN = 'COINSPECT_PAN',
	COINSPECT_PR = 'COINSPECT_PR',
}

export const ACCOUNTS_WITH_EXTERNAL_INFO_FILLED = [MMM];

export const GEOLOCATION_OPTIONS = {
	enableHighAccuracy: true,
	maximumAge: 10000,
};

export const BranchDefaultCoords = {
	[Branches.PR]: { lat: 18.449807, lng: -66.073389 },
	[Branches.PTY]: { lat: 8.985131, lng: -79.5213 },
	[Branches.CRI]: { lat: 9.934735, lng: -84.097326 },
	[Branches.CO]: { lat: 4.670682, lng: -74.10022 },
	[Branches.MX]: { lat: 19.423976, lng: -99.140633 },
};

export const TIME_TO_REQUEST_DRIVER_LOCATION = 5000;

export enum CURRENCY {
	USD = 'usd',
	CRC = 'crc',
}

export const OTIUM_POLICY_NUMBERS = [
	'SanJorge4233A',
	'SanJorge4233B',
	'Ashforf8902A',
	'Cacique2C',
	'Cacique4A',
	'CC2F',
	'Terrace9D',
	'Cacique3B',
	'Cacique4C',
	'CDM2012',
	'CDM505',
	'GrandBay121',
	'ODonnell153',
	'Corchado119',
	'GoldenBeach502',
	'VillaMarina',
	'Atlantis1906',
	'GrandReserve404',
	'VillaPlaya1',
	'VillaPlaya2',
	'VillaPlaya3',
	'VillaPlaya4',
	'VillaPlaya5',
	'VillaPlaya6',
	'VillaPlaya7',
	'Elena121A',
	'Elena121B',
	'Elena122A',
	'Elena122B',
	'Elena123A',
	'Condado65101',
	'Condado65102',
	'Condado65103',
	'Condado65104',
	'Condado65201',
	'Condado65202',
	'Condado65203',
	'Condado65204',
	'Condado65301',
	'Condado65302',
];
