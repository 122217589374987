import OneSignal from 'react-onesignal';
import { DispatchFunction } from '../interfaces';
import { setPushEnable } from '../utils/Utils';
import HeliosClient from './Helios';

export const initOneSignal = async (accountName: string | null) => {
	const appId = process.env.REACT_APP_ONESIGNAL_APP_ID;
	if (appId) {
		const safariWebId = process.env.REACT_APP_ONESIGNAL_SAFARI_WEB_ID;
		const initialization = await OneSignal.init({
			appId: appId,
			safari_web_id: safariWebId,
			allowLocalhostAsSecureOrigin: true,
			promptOptions: {
				slidedown: {
					prompts: [
						{
							type: 'push',
							autoPrompt: true,
							text: {
								actionMessage: `${accountName} le gustaría enviarte Notificaciones de tu Servicio `,
								acceptButton: 'Ok',
								cancelButton: 'No Permitir',
							},
							delay: {
								pageViews: 1,
								timeDelay: 5,
							},
						},
					],
				},
			},
		});

		OneSignal.on('notificationDisplay', () => (event: any): void => {
			console.warn('OneSignal notification displayed:', event);
		});

		OneSignal.on('notificationDismiss', () => (event: any): void => {
			console.log('OneSignal notification dismissed:', event);
		});

		return initialization;
	}
};

export const subscribeOneSignal = async (serviceNumber: string, dispatch: DispatchFunction): Promise<void> => {
	await new HeliosClient().setPushOptInService(serviceNumber, await OneSignal.isPushNotificationsEnabled());
	OneSignal.setExternalUserId(serviceNumber).then(() => {
		setPushEnable(dispatch);
	});
};
