import HeliosClient from '../../api/Helios';
import { Brand, CORPORATE_ACCOUNTS, HIDE_PLATE_FIELD, MMM, MMM_ACCOUNT } from '../../utils/constants';
import { setCheckLimitErrors, setClientInfo, setCustomerContract, setServiceData } from '../../context/actions';
import * as Sentry from '@sentry/react';
import {
	Account,
	AppContext,
	CustomerContract,
	DispatchFunction,
	POLICY_LIMIT_VERSION,
	TRACKS,
} from '../../interfaces';

export const submitSearchContractAndGetRedirect = async (
	ctx: AppContext,
	dispatch: DispatchFunction,
	plate: string,
	fullName: string,
	mileage?: string,
	memberId?: string
): Promise<'/other-options/vehicleNotFound' | '/location' | '/clients' | '/other-options/exceedLimit' | ''> => {
	const { brand, account, situation, service, clientForm, currentTrack } = ctx;

	let includePlateInCheckLimit = true;
	let searchField = HIDE_PLATE_FIELD.includes(brand) ? fullName : plate;

	if (brand === MMM || brand === Brand.MULTIPLE_ASISTENCIA_EN_EL_HOGAR) {
		searchField = memberId || '';
	} else if (brand === Brand.OTIUM) {
		includePlateInCheckLimit = false; // Para esta cuenta, el check limit se hace por el "defaultPolicyIdentifier"
		searchField = ctx.clientForm?.policyNumber!;
	}

	try {
		const responseSearch = await new HeliosClient().contractSearch(
			searchField,
			account.salesforce,
			account.branch,
			fullName,
			true
		);
		const search = responseSearch.data.data;

		if (search.length === 0) {
			return '/other-options/vehicleNotFound';
		} else {
			const customerContract = search[0];
			const currentPlate = search[0].vehicle?.plate || plate;
			let vehicleResponse: any = undefined;
			if (customerContract.account.salesforce_id === MMM_ACCOUNT) {
				vehicleResponse = await new HeliosClient().getVehicleMemberMMM(customerContract.extras?.mbi);
			}
			const info = {
				fullName: customerContract.customer.full_name,
				phone: customerContract.customer.phone_number1,
				plate: includePlateInCheckLimit ? currentPlate?.replace(/-/g, '') : undefined,
				make: customerContract.vehicle?.make || vehicleResponse?.data?.make,
				model: customerContract.vehicle?.model || vehicleResponse?.data?.model,
				year: customerContract.vehicle?.year || vehicleResponse?.data?.year,
				vin: customerContract.vehicle?.vin || vehicleResponse?.data?.vin,
				color: customerContract.vehicle?.color || vehicleResponse?.data?.color,
				memberId: memberId,
				mbi: customerContract.extras?.mbi,
				privacyPolicyAcceptanceDate: clientForm.privacyPolicyAcceptanceDate,
			};

			setClientInfo(info, dispatch);
			setCustomerContract(customerContract, dispatch);

			const checkLimitResponse = await checkLimit(account, situation, customerContract, info.plate);

			if (!checkLimitResponse.shouldCheckLimit || !checkLimitResponse.setOnDemand) {
				// Si el brand esta en CORPORATE_ACCOUNTS  se guarda el millaje
				if (CORPORATE_ACCOUNTS.includes(brand)) {
					setServiceData({ ...service, external: { id: mileage } }, dispatch, '');
				}

				if (currentTrack === TRACKS.HOME) {
					return '/location';
				}
				return '/clients';
			} else {
				if (checkLimitResponse.plateDifference) {
					return '/other-options/vehicleNotFound';
				} else {
					const errorMessages = checkLimitResponse.messages?.map((error: any) => error.message) || [];

					if (errorMessages?.length && account.config?.policyLimitVersion === POLICY_LIMIT_VERSION.POLICY_LIMIT_V2) {
						setCheckLimitErrors(dispatch, errorMessages);
					}
					// Ir a pantalla de limite de servicios.
					return '/other-options/exceedLimit';
				}
			}
		}
	} catch (error) {
		console.log('Search Plate Error: ', error);
		Sentry.captureException(error);
		return '';
	}
};

const checkLimit = async (account: Account, situation: string, customerContract: CustomerContract, plate?: string) => {
	const serviceData = {
		serviceData: {
			branch: account.branch,
			situation: situation,
			providerDistance: null,
			distance: null,
			customerContract,
			customerFeeParams: {
				branch: account.branch,
				fees: JSON.stringify({
					management: account?.managementFee,
					situation: account.serviceFees?.[situation],
				}),
				providerCosts: { serviceCost: 0 },
				sfIdAccount: account.salesforce,
			},
			accountSfId: account.salesforce,
			plate,
		},
	};

	const checkLimitServices: any = await new HeliosClient().checkLimit(serviceData);

	return checkLimitServices.data.data;
};
