import React, { useContext, useEffect, FunctionComponent } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Context } from '../../context/Context';
import { AppContext } from '../../interfaces';
import { setBrand, setCustomerContract, setMMMHOD, setuserExternalParams } from '../../context/actions';
import { createQueryParams, generateRandomString, pkceChallengeFromVerifier } from '../../utils/oauth';

import '../LandingPage/LandingPage.scss';
import {
	Brand,
	HOME_ON_ONDEMAND_ACCOUNT_ID,
	MMM_ACCOUNT_ID,
	MMM_ACCOUNT_SFID_ACCOUNT,
	MMM_AUTH_ENDPOINT,
	MMM_CLIENT_ID,
	MMM_REDIRECT_URI,
	MMM_SCOPE,
} from '../../utils/constants';
import { getImagesByBrand } from '../../utils/Utils';
import CommonApi from '../../api/CommonApi';

import './LandingPageMMM.scss';
import HeliosClient from '../../api/Helios';

// IMPORTANT: This implementation follows:
// https://developer.okta.com/blog/2019/05/01/is-the-oauth-implicit-flow-dead
// Read link for more details

const CLIENT_ID_OPEN_ID = MMM_CLIENT_ID;
const REDIRECT_URI_OPEN_ID = MMM_REDIRECT_URI;
const SCOPE_OPEN_ID = MMM_SCOPE;
const URL_AUTH_OPEN_ID = MMM_AUTH_ENDPOINT;

export const MMMLanding: FunctionComponent = (): JSX.Element => {
	const query = useQuery();
	const product = query.get('product');

	useEffect(() => {
		localStorage.setItem('product', product as string);
		login();
	}, []);

	const login = async () => {
		// Create and store a random "state" value
		const state = generateRandomString();
		localStorage.setItem('pkce_state', state);

		// Create and store a new PKCE code_verifier (the plaintext random secret)
		const codeVerifier = generateRandomString();
		localStorage.setItem('pkce_code_verifier', codeVerifier);

		// Hash and base64-urlencode the secret to use as the challenge
		const codeChallenge = await pkceChallengeFromVerifier(codeVerifier);

		const queryStringObj = {
			response_type: 'code',
			client_id: encodeURIComponent(CLIENT_ID_OPEN_ID),
			state: encodeURIComponent(state),
			scope: encodeURIComponent(SCOPE_OPEN_ID),
			redirect_uri: encodeURIComponent(REDIRECT_URI_OPEN_ID),
			code_challenge: encodeURIComponent(codeChallenge),
			code_challenge_method: 'S256',
		};

		// Build the authorization URL
		const url = `${URL_AUTH_OPEN_ID}/authorize?${createQueryParams(queryStringObj)}`;

		// Redirect to the authorization server
		window.location.href = url;
	};
	return <></>;
};

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export const MMMCallback: FunctionComponent = (): JSX.Element => {
	const history = useHistory();
	const { dispatch } = useContext(Context) as AppContext;

	const query = useQuery();
	const code = query.get('code');
	const state = query.get('state');
	const error = query.get('error');

	const getMMMUserInfo = async (code: string) => {
		const response = await new CommonApi().getUserInfo({
			grant_type: 'authorization_code',
			client_id: CLIENT_ID_OPEN_ID,
			redirect_uri: REDIRECT_URI_OPEN_ID,
			code_verifier: localStorage.getItem('pkce_code_verifier'),
			code: code,
		});
		window.history.replaceState({}, null as any, '/');
		const productTrack = ['HOME', 'ROAD'].includes(localStorage.getItem('product') as string)
			? localStorage.getItem('product')?.toLocaleLowerCase()
			: null;

		// Remove unnecessary state from localStorage
		localStorage.removeItem('pkce_state');
		localStorage.removeItem('pkce_code_verifier');
		localStorage.removeItem('product');

		const externalInfoName = `${response.data.userInfo.firstname} ${response.data.userInfo.lastname}`;
		const externalInfoIdentifier = response.data.userInfo.cardnumber;

		setuserExternalParams(externalInfoIdentifier, externalInfoName, false, null, dispatch);
		setBrand({ brand: Brand.MMM }, dispatch);

		if (!productTrack) {
			const responseSearch = await new HeliosClient().contractSearch(
				externalInfoIdentifier,
				MMM_ACCOUNT_SFID_ACCOUNT,
				Brand.MMM,
				externalInfoName,
				true
			);

			const search = responseSearch.data.data;
			if (search.length > 0) {
				const customerContract = search[0];
				setCustomerContract(customerContract, dispatch);
				setMMMHOD(true, dispatch);
			}
		}

		const redirectUrl = productTrack
			? `/?account=${MMM_ACCOUNT_ID}&brand=${Brand.MMM}&track=${productTrack}`
			: `/?account=${HOME_ON_ONDEMAND_ACCOUNT_ID}&brand=${Brand.HOME_ON_DEMAND_PR}`;

		history.replace(redirectUrl);
	};

	useEffect(() => {
		// Check for tampering or error, raise error.
		if (!code || !state || error || localStorage.getItem('pkce_state') !== state) {
			alert('Hubo un error en la autenticación');
			return;
		}

		getMMMUserInfo(code);
		// eslint-disable-next-line
	}, []);

	return (
		<div className="mmm-landing-page">
			<div className="mmm-landing-page__header">
				<figure className="mmm-landing-page__logo">
					<img width="150" className="mmm-landing-page__logo-img" src={getImagesByBrand('mmm').logoDark} alt="logo" />
				</figure>
			</div>
			<div className="mmm-landing-page__footer">
				<div className="mmm-landing-page__loader border-primary-color border-right-transparent-color" />
			</div>
		</div>
	);
};
