import React, { useState, useContext } from 'react';

import cn from 'classnames';
import Modal from '../Modal';
import HelpModal from '../HelpModal';
import { Context } from '../../../context/Context';
import { AppContext } from '../../../interfaces';
import { selectCategory } from '../../../context/actions';

import { ReactComponent as OtherHelp } from '../../../assets/imgs/other_help.svg';
import i18n from '../../../utils/i18n';

const OtherServiceHelp = () => {
	const [showHelpModal, setShowHelpModal] = useState(false);
	const ctx = useContext(Context) as AppContext;
	const { dispatch } = ctx;

	const onDimissClick = (): void => {
		setShowHelpModal(false);
	};

	const onHelpClick = (): void => {
		selectCategory(undefined, dispatch);
		setShowHelpModal(true);
	};

	return (
		<>
			<div
				className={cn('services__service border-light-primary-color', {
					'bg-primary': showHelpModal,
				})}
				onClick={(): void => onHelpClick()}
			>
				<OtherHelp
					className={cn('services__service-icon', {
						'svg-color': !showHelpModal,
						'svg-white': showHelpModal,
					})}
				/>
				<p
					className={cn('services__service-label', {
						'white-color': showHelpModal,
					})}
				>
					{i18n.get('bottom_sheet.other_service_btn', 'Otro')}
				</p>
			</div>
			{showHelpModal && (
				<Modal dismiss={onDimissClick} containerClassName={'modal__container--help'}>
					<HelpModal dismiss={onDimissClick} />
				</Modal>
			)}
		</>
	);
};

export default OtherServiceHelp;
