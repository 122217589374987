import React, { useContext, useEffect, FunctionComponent, useState, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import cn from 'classnames';
import { Context } from '../../context/Context';
import { ActionType, AppContext, DispatchFunction } from '../../interfaces';
import { getImagesByBrand } from '../../utils/Utils';
import '../LandingPage/LandingPage.scss';
import { setAccount, setBrand, setClientInfo, setServiceTypes } from '../../context/actions';
import { MMM, MMM_ACCOUNT } from '../../utils/constants';
import HeliosClient from '../../api/Helios';

const LandingPageMMM: FunctionComponent = (): JSX.Element => {
	const [visible, setVisible] = useState<boolean>(false);
	const location = useLocation();
	const history = useHistory();
	const { dispatch, brand } = useContext(Context) as AppContext;

	useEffect(() => {
		dispatch({ type: ActionType.CLEAR_ALL });
		const MMM_ACCOUNT_ID = MMM_ACCOUNT;
		const queryParams = new URLSearchParams(location.search);
		const clientInfo = {
			fullName: queryParams.get('name'),
			memberId: queryParams.get('memberId'),
			plate: '',
		};

		getAccount(MMM_ACCOUNT_ID, dispatch);
		getServiceTypes(dispatch);
		setBrand({ brand: MMM }, dispatch);
		setClientInfo(clientInfo, dispatch);
		setVisible(true);

		const timer = setTimeout(() => {
			history.replace(`/services`);
		}, 1000);
		return (): void => clearTimeout(timer);

		// eslint-disable-next-line
	}, []);

	const getServiceTypes = useCallback(async (dispatch: DispatchFunction): Promise<void> => {
		const {
			data: { data },
		} = await new HeliosClient().fetchServiceTypes();
		setServiceTypes(dispatch, data);
	}, []);

	const getAccount = useCallback(async (accountId: string, dispatch: DispatchFunction): Promise<void> => {
		const {
			data: { data },
		} = await new HeliosClient().getAccountBySalesForceId(accountId);
		setAccount(data, dispatch);
	}, []);

	const classname = cn({
		hidden: !visible,
		'landing-page': visible,
	});

	return (
		<div className={classname}>
			<figure className="landing-page__logo">
				<img width="300" src={getImagesByBrand(brand).logoDark} alt="logo" />
			</figure>
			<div className="landing-page__footer">
				<div className="landing-page__loader" />
			</div>
		</div>
	);
};

export default LandingPageMMM;
